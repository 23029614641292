.errorTitle{
    font-size: 44px;
    font-weight: bold;
    text-align: center;
    padding-top: 6vh;
    padding-bottom: 4vh;
    margin-right: 10%;
    margin-left: 10%;
}

.errorText {
  font-size: 14px;
  text-align: center;
}

.errorButtonBack {
  position: absolute;
  bottom: 10%;
  left: 4%;
}

@media screen and (max-width: 1020px) {
  .errorButtonWrapper {
    position: fixed;
    bottom: 0;
  }
  .errorText {
    text-align: left;
    padding-left: 3vw;
  }
}

@media screen and (max-width: 420px) {
  .errorTitle {
    font-size: 32px;
  }
  .errorText {
    font-size: 13px;
  }
}

@media screen and (max-width: 300px) {
  .errorTitle {
    font-size: 24px;
  }
}
