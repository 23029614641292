.employeePage {
  padding-top: 10vh;
  padding-bottom: 10vh;
  height: 90vh;
}

.employeeTitle {
  font-size: 44px;
  font-weight: bold;
  color: black;
  padding-top: 4vh;
  padding-bottom: 4vh;
  text-align: center;
}

.explanations {
  font-size: 14px;
  text-align: center;
  margin: 0;
}

.fieldWrapper {
  display:flex;
  justify-content: space-between;
  padding: 0 5vw;
  margin: auto;
}

.employeePageColumn {
  flex: 0 0 300px;
  height: 10rem;
}

.text {
  width: 30vw;
  margin: auto;
  font-size: 16px;
}

.error {
  position: "relative";
  font-weight: bold;
  color: rgb(255, 0, 0);
}

.info {
  font-size: 12px;
  display: block;
}

.employeeButtonBack {
  position: absolute;
  bottom: 10%;
  left: 4%;
}

.employeeButtonContinue {
  position: absolute;
  bottom: 10%;
  right: 4%;
}

.fieldName {
  position: relative;
  left: 3%;
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.fieldWrapper .formBox {
  margin-bottom: 16px;
  background-color: #dcd6d6;
  border-radius: 5px;
  display: inline-block;
}

.employee-select {
  margin-bottom: 16px;
}

.paragraph {
  margin-bottom: 40px;
  line-height: 1.5;
  font-family: "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  text-align: center;
}

.employeeTooltip {
  display: none;
}

@media screen and (max-width: 1020px) {
  .employeePage {
    overflow-y: auto;
    position: relative;
    z-index: 10;
    height: calc(100vh - 92px);
    padding-bottom: 20px;
    background-color: white;
  }
  .employeePageInformations {
    display: block;
  }
  .employeePageInformationsWrapper {
    padding-left: 6vw;
  }
  .explanations {
    text-align: left;
    padding-left: 3vw;
  }
  .fieldWrapper {
    flex-direction: column;
    align-content: space-between;
    width: 100vw;
  }
  .employeePageColumn {
    flex: 0 0 120px;
    padding: 0 3vw;
  }
  .formBox {
    margin-bottom: 1vh;
  }
  .info {
    display: none;
  }
  .error {
    font-size: 14px;
  }
  .employeeTooltip {
    display: inline;
    padding-left: 1vw;
  }
  .employeeButtonWrapper {
    position: fixed;
    bottom: 0;
  }
  .employeeButtonContinue {
    position: unset;
    background-color: #adcfe6 !important;
    border: 0;
  }
  .employeeButtonBack {
    position: unset;
  }
}

@media screen and (max-width: 600px) {
  .text {
    width: 100vw;
  }
}

@media screen and (max-width: 420px) {
  .employeeTitle {
    font-size: 32px;
  }
  .explanations {
    font-size: 13px;
  }
}

@media screen and (max-width: 300px) {
  .employeeTitle {
    font-size: 24px;
  }
}
