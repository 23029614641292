.middleVip{
    height: 100vh;
}
.vipTitle {
    font-size: 44px;
    font-weight: bold;
    color: black;
    margin-top: 4%;
    margin-bottom: 4%;
    text-align: center;
}

.columnLeft1 {
    float: left;
    padding-left: 10% !important;
    padding-right: 5% !important;
    width: 50%;
    border-right-color: #04006c;
}

.columnLeft2 {
    float: left;
    width: 33.3%;
}

.columnRight1 {
    padding-left: 5% !important;
    padding-right: 10% !important;
    float: left;
    width: 50%;
}

.columnRight2 {
    float: left;
    width: 33.3%;
}

.columnMiddle {
    float: left;
    width: 33.3%;
}

.formBoxBusiness {
    line-height: 32px;
    height: 100px !important;
    margin-bottom: 16px;
    background-color: #dcd6d6;
    border-radius: 5px;
    display: inline-block;
}

.section {
    margin-bottom: 10%;
    text-align: center;
}

.underSection {
    font-weight: bold;
    font-size: 20px
}

.boxChoice {
    padding: 2% !important;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    border: 2px solid rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    width: 100%;
}

.textVip {
    margin-left: 20%;
}