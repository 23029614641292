.top {
  background: url("./images/logo.svg");
  background-size: 12em;
  background-repeat: no-repeat;
  background-position: 3%;
  background-color: #04006c;
  font-size: calc(10px + 2vmin);
  height: 10vh;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
}