.navBar {
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.button-proceed {
  font-weight: bold;
  background: #04006c !important;
  background-color: #04006c !important;
  border-color: #04006c !important;
  color: rgb(255, 255, 255);
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  transform: translateX(-50%);
}

.navbar button {
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  .navbar button {
    width: 100vw;
    border-radius: 0;
  }
}


/* .button-proceed{
    font-weight: bold;
    background: #04006c !important;
    background-color: #04006c !important;
    border-color: #04006c !important;
    color: rgb(255, 255, 255);
    padding: 16px 32px;
    margin-inline-start: 24px;
    justify-content: center;
    float: left;
    width: 20%;
} */
