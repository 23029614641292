.middle{
  position: absolute;
  top: 0;
  background:url("./images/earth.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding: 20vh 0;
  width: 100%;
}

.middle p {
  padding: 0 5vh;
}

.homeTitle {
  font-size: calc(22px + 2vmin);
  font-weight: bold;
  color: #fff;
  text-shadow: 0 2px 2px rgb(0 0 0 / 30%);
  text-align: center;
}

.homeParagraph {
  font-size: calc(10px + 1vmin);
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
  color: white;
  text-align: center;
  justify-content: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
}