.center {
  color: black;
  font-weight: bold;
  font-size: 24px;
  position: absolute;
  text-align: center;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #c6bbbb;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}