.bottom {
  height: 10vh;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.bottomMiddle {
  height: 100%;
  float: left;
  width: 50%;
  background-color: #04006c;
}

.bottomMiddleText {
  height: 100%;
  width: 100%;
  color: lightgray;
  font-size: 12px; 
  display: inline-flex;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 420px) {
  .bottomMiddleText {
    font-size: 9px; 
  }
}

@media screen and (max-height: 750px) {
  .bottomMiddleText {
    font-size: 9px; 
  }
}

@media screen and (max-width: 300px) {
  .bottomMiddleText {
    font-size: 8px; 
  }
}


.bottomRight {
  height: 100%;
  float: left;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #04006c;
  background-position: right;
  width: 25%;
}

.bottomLeft{
  height: 100%;
  float: left;
  background-color: #04006c;
  width: 25%;
}

/* .bottomLeft {
  height: 100%;
  float: left;
  background: url("./images/socialNetwork_logo.PNG");
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #04006c;
  background-position: left;
  width: 25%;
} */
