.button {
  font-weight: bold;
  font-size: 16px;
  background: linear-gradient(#0000, rgb(0 0 0/40%)) top/100% 800%;
  background-color: #04006c !important;
  border-color: #04006c !important;
  border-radius: 0.25rem;
  color: rgb(255, 255, 255);
  padding: 16px 32px;
  margin-bottom: 1%;
}

.button:hover {
  background-position: bottom;
}

@media screen and (max-width: 1020px) {
  .button {
    margin-bottom: 0;
    width: 100vw;
    border-radius: 0 !important;
    border: unset !important;
    padding: 0.5rem 0.75rem !important;
  }
}