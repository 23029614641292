button:focus {box-shadow:none !important;}

.mobile {
  display: none;
}

@media screen and (max-width: 1020px) {
  .mobile {
    display: inline;
  }
  .desktop {
    display: none;
  }
}

.bold {
  font-weight: bold;
}

@media screen and (max-width: 420px) {
  .successTitleEmployee {
    font-size: 32px;
  }
}

@media screen and (max-width: 300px) {
  .successTitleEmployee {
    font-size: 24px;
  }
  .text-center {
    font-size: 12px;
  }
}