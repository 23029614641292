.successTitleEmployee {
  font-size: 44px;
  font-weight: bold;
  padding-top: 4vh;
  padding-bottom: 4vh;
  text-align: center;
}

.mailto {
  font-weight: bold;
  font-size: 16px;
  background: #04006c !important;
  background-color: #04006c !important;
  border-color: #04006c !important;
  border-radius: 0.25rem;
  color: rgb(255, 255, 255);
  padding: 16px 32px;
  margin: auto;
  display: block;
  max-width: fit-content;
  text-decoration: none;
}

.successText {
  font-size: 14px;
  text-align: center;
}

.successButtonBack {
  position: absolute;
  bottom: 10%;
  left: 4%;
}

@media screen and (max-width: 1020px) {
  .successButtonWrapper {
    position: fixed;
    bottom: 0;
  }
  .successText {
    text-align: left;
    padding-left: 3vw;
  }
  .mailto {
    font-size: 13px;
    padding: 8px 16px;
    margin-left: 3vw;
  }
}

@media screen and (max-width: 420px) {
  .successTitleEmployee {
    font-size: 32px;
  }
  .successText {
    font-size: 13px;
  }
}

@media screen and (max-width: 300px) {
  .successTitleEmployee {
    font-size: 24px;
  }
}

